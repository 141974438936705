import React, { useState, useEffect } from 'react'
import Select from 'react-select'

export default function SearchableSelect({ optionsUrl, inputName, inputId, label }) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
 
  async function fetchdata() {
    const response = await fetch(optionsUrl);
    const responseBody = await response.json();
    return (responseBody)
  }

  useEffect(() => {
    setLoading(true)
    // call the function
    fetchdata()
      .then((res) => {
        setData(res)
        setLoading(false)
      })
      // make sure to catch any error
      .catch((e) => console.log(e));
  }, [])

  const options = Object.keys(data).map((k) => ({ label: data[k], value: k}))
  return (
    <div className="control-group integer optional">
      <label className="integer optional control-label" for={inputName}>{label}</label>
        <div className="controls">
          <Select
            id={inputId}
            name={inputName}
            options={options}
            placeholder="Select an Option"
            isClearable
            isDisabled={loading}
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor: '#9e9e9e',
                borderRadius: '4px',
                minHeight: '25px',
                height: '25px',
                boxShadow: state.isFocused ? null : null,
              }),
          
              valueContainer: (provided, state) => ({
                ...provided,
                height: '25px',
                padding: '0 6px'
              }),
          
              input: (provided, state) => ({
                ...provided,
                margin: '0px',
              }),
              indicatorSeparator: state => ({
                display: 'none',
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '25px',
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                zIndex: '8000',
              }),
            }}
          />
        </div>
    </div>
  )
}