import React, { useState } from 'react';
import { FeatureGroup, GeoJSON, MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import EditControl from '../components/maps/EditControl';
import bbox from 'geojson-bbox';
import { uniqFeatures } from "../utilities";
import ActivitiesTable from '../components/maps/ActivitiesTable';
import ActivitySheetLayers from './ActivitySheetLayers';

export default function ActivitiesMap({ fieldOutline, prescribedOutline, distSaleOutline, activityId, activitySheets }) {
  const extent = bbox(fieldOutline); 
  const [drawEnabled, setDrawEnabled] = useState(false)
  const activityFeatures = (prescribedOutline?.features || []).map((f, i) => ({...f, properties: { persistedToDb: true }}))
  const [features, xSetFeatures] = useState(activityFeatures)

  const setFeatures = (list) => {
    xSetFeatures(uniqFeatures(list))
  }

  const removeFeature = (indexToRemove) => {
    const result = [...features.slice(0, indexToRemove), ...features.slice(indexToRemove + 1)];
    xSetFeatures(result)
  }

  const setWholeField = () => {
    xSetFeatures(fieldOutline.features)
  }

  const resetFeaturesFromBackend = (features) => {
    const persistedFeatures = features.map((f, i) => ({...f, properties: { persistedToDb: true }}))
    xSetFeatures(persistedFeatures)
  }

  return(
    <>
      <MapContainer
        bounds={[[extent[1],extent[0]], [extent[3], extent[2]]]}
        scrollWheelZoom
        style={{ height: '420px' }}
      >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name="Field Outline">
            <GeoJSON data={fieldOutline} pathOptions={{ color: '#6988E7', fillOpacity: 0 }} />
          </LayersControl.Overlay>
          {
            distSaleOutline
              ? (
                <LayersControl.Overlay checked name="Distribution Sale Outline">
                  <GeoJSON data={{ type: 'FeatureCollection', features: distSaleOutline }} pathOptions={{ color: 'green' }} />
                </LayersControl.Overlay>
              ) : null
          }
          <LayersControl.Overlay checked name="Planned Activity">
            <FeatureGroup key={JSON.stringify(features)} pathOptions={{ color: 'yellow' }}>
              <EditControl
                edit={{ edit: false, remove: false, }}
                position='bottomleft'
                features={features}
                skipFitBoundsOnChange
                setFeatures={setFeatures}
                draw={{ 
                  circlemarker: false, 
                  marker: false, 
                  circle: false, 
                  polyline: false,
                  rectangle: drawEnabled ? { shapeOptions: { color: 'yellow', opacity: 1 } } : null,
                  polygon: drawEnabled ? { shapeOptions: { color: 'yellow', opacity: 1 } } : null
                }}
              />
            </FeatureGroup>
          </LayersControl.Overlay>
          <ActivitySheetLayers activitySheets={activitySheets} />
        </LayersControl>

      </MapContainer>

      <ActivitiesTable 
        features={features}
        activityFeatures={activityFeatures}
        setWholeField={setWholeField}
        setDrawEnabled={setDrawEnabled}
        drawEnabled={drawEnabled}
        removeFeature={removeFeature}
        activityId={activityId}
        resetFeaturesFromBackend={resetFeaturesFromBackend}
      />

    </>
  )
}
