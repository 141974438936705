import React, { useState } from 'react';

export default function ActivityDetailCard({ data }) {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      <div className="widget-head" style={{backgroundColor: "rgb(51, 51, 51)"}}>
        <div className="title">Activity Sheet</div>
        <div>
        <button
          style={{ float: 'right' }}
          className={`iconfa-circle-arrow-${expanded ? 'up' : 'down'}`}
          onClick={(e) => {
            e.preventDefault();
            setExpanded(!expanded);
          } }
        />
          </div>
      </div>
      <div>
        <div className="row-fluid">
          <table className="table-border-left table table-bordered table-striped">
            <tbody>
              <tr>
                <td><b style={{ marginRight: 3 }}>Job Code:</b><span>{data.job_plan?.job_code}</span></td>
                <td><b style={{ marginRight: 3 }}>Client:</b><span>{data.client}</span></td>
              </tr>
              {expanded
                ? (
                  <>
                    <tr>
                      <td><b style={{ marginRight: 3 }}>Crop Year:</b><span>{data.year}</span></td>
                      <td><b style={{ marginRight: 3 }}>Main Activity:</b><span>{data.activity_type}</span></td>
                    </tr>
                    <tr>
                      <td><b style={{ marginRight: 3 }}>Farm:</b><span>{data.farm}</span></td>
                      <td><b style={{ marginRight: 3 }}>Field:</b><span>{data.field}</span></td>
                    </tr>
                    <tr>
                      <td><b style={{ marginRight: 3 }}>Acres Planned:</b><span>{data.acres_planned}</span></td>
                      <td><b style={{ marginRight: 3 }}>Application Type:</b><span>{data.application_type}</span></td>
                    </tr>
                  </>
                )
                : null}
            </tbody>
          </table>
        </div>
        {data.planned_activities.map((pa, index) => (
          <div key={pa.id}>
            <table className="table-border-left table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>
                    <b style={{ marginRight: 3 }}>Product Amount:</b>
                    <span>{pa.product_amount}</span>
                  </td>
                  <td>
                    <b style={{ marginRight: 3 }}>Rate/Acre:</b>
                    <span>{pa.rate_per_acre}</span>
                  </td>
                </tr>
                {expanded
                  ? (
                    <>
                      <tr>
                        <td>
                          <b style={{ marginRight: 3 }}>Product Name:</b><span>{pa.product_name}</span>
                        </td>
                        <td>
                          <b style={{ marginRight: 3 }}>Product Type:</b><span>{pa.product_type}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b style={{ marginRight: 3 }}>Product Storage:</b><span>{pa.product_storage}</span>
                        </td>
                        <td>
                          <b style={{ marginRight: 3 }}>Planned Method of Application:</b>
                          <span>{pa.application_method}</span>
                        </td>
                      </tr>
                    </>
                  )
                  : null}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>

  ) 
}