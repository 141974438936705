import { useEffect } from 'react';
const useLegalAddress = (debouncedValue, legalDesc, setLoading, setLegalAddressRecord) => {
  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      const searchParams = new URLSearchParams({
        legal1: legalDesc.legal1, 
        legal2: legalDesc.legal2, 
        range: legalDesc.range, 
        range_direction: legalDesc.range_direction,
        section: legalDesc.section,
        state: legalDesc.state,
        township: legalDesc.township,
        township_direction: legalDesc.township_direction
      })
      const response = await fetch("/api/legal_addresses?" + searchParams);
      const data = await response.json();
      return (data)
    }

    // call the function
    fetchData()
      .then((res) => {
        setLegalAddressRecord(res.legal_address)
        setLoading(false)
      })
      // make sure to catch any error
      .catch(console.error);
  }, [JSON.stringify(debouncedValue)])
}

export default useLegalAddress;