import React, { useEffect, useState } from 'react';

function removeDuplicates(arr) {
  const jsonObject = arr.map(JSON.stringify);
  const uniqueSet = new Set(jsonObject);
  return Array.from(uniqueSet).map(JSON.parse);
}

export default function NutrientApplicationReportSubForm({ actualActivityOptions }) {
  const [productId, setProductId] = useState(null)
  const [date, setDate] = useState("")
  const [displayDate, setDisplayDate] = useState("")
  const [amountApplied, setAmountApplied] = useState(null)
  const [acresApplied, setAcresApplied] = useState(null)

  const productOptions = removeDuplicates(actualActivityOptions.map(act_act => act_act.product));
  const dateOfAppOptions = removeDuplicates(actualActivityOptions.filter(o => o.product_id === productId).map(o => o.activity_application.start_date))
  const latestAppDateArr = dateOfAppOptions.sort(function(a,b) {
    a = a.split('-').reverse().join('');
    b = b.split('-').reverse().join('');
    return a > b ? -1 : a < b ? 1 : 0;
  });
  const latestAppDate = latestAppDateArr[0];

  useEffect(() => {
    if (productOptions.length === 1) {
      setProductId(productOptions[0].id)
    }
  }, [])

  const onChangeDateOfApplication = (v) => {
    setDisplayDate(v)
    if (v.includes('all')) {
      const acresAppliedTotal = actualActivityOptions.reduce((n, {acres_applied}) => n + acres_applied, 0)
      const amountApplied = actualActivityOptions.reduce((n, {amount_applied}) => n + amount_applied, 0)
      setDate(latestAppDate)
      setAmountApplied(amountApplied || 0)
      setAcresApplied(acresAppliedTotal || 0)
    } else {
      const actualActivity = actualActivityOptions.find((act_act) => act_act.product_id === productId && act_act.activity_application.start_date === v)
      setDate(v)
      setAmountApplied(actualActivity.amount_applied || 0)
      setAcresApplied(actualActivity.acres_applied || 0)
    }
  }
  
  return (
    <>
      <div className="control-group select optional nutrient_application_report_product">
        <label className="select optional control-label" for="nutrient_application_report_product_id">
          Product
        </label>
        <div className="controls">
          <select
            style={{ width: '100%', borderRadius: 5, height: 27 }}
            className="select optional chosen-container"
            name="nutrient_application_report[product_id]"
            id="nutrient_application_report_product_id"
            onChange={(e) => setProductId(e.target.value)}
          >
            {productOptions.map(option => <option value={option.id}>{option.name}</option>)}
          </select>
        </div>
      </div>
      <div className="control-group select optional nutrient_application_report_product">
        <label className="select optional control-label" for="nutrient_application_report_product_id">
          Date of Application
        </label>
        <div style={{display: 'none'}}>
          <input
            name="nutrient_application_report[date_of_application]"
            id="nutrient_application_report_date_of_application"
            value={date}
          />
        </div>
        <div className="controls">
          <select
            style={{ width: '100%', borderRadius: 5, height: 27 }}
            className="select optional"
            name="date_display"
            id="date_display"
            value={displayDate}
            onChange={(e) => onChangeDateOfApplication(e.target.value)}
          >
            <option value={""} disabled>Select a Date...</option>
            {dateOfAppOptions.length > 1 ? <option value="all">All Dates</option> : null}
            {dateOfAppOptions.map(option => <option value={option}>{option}</option>)}
          </select>
        </div>
      </div>
      <div className="control-group float optional nutrient_application_report_amount_applied">
        <label className="float optional control-label" for="nutrient_application_report_amount_applied">
          Amount Applied
        </label>
        <div className="controls">
          <input
            className="numeric float optional span12 span12"
            type="number"
            value={amountApplied}
            onChange={(e) => setAmountApplied(e.target.value)}
            step="any"
            name="nutrient_application_report[amount_applied]"
            id="nutrient_application_report_amount_applied"
          />
        </div>
      </div>
      <div className="control-group float optional nutrient_application_report_acres_applied">
        <label className="float optional control-label" for="nutrient_application_report_acres_applied">
          Acres Applied
        </label>
        <div className="controls">
          <input
            className="numeric float optional span12 span12"
            type="number"
            value={acresApplied}
            onChange={(e) => setAcresApplied(e.target.value)}
            step="any"
            name="nutrient_application_report[acres_applied]"
            id="nutrient_application_report_acres_applied"
          />
        </div>
      </div>
    </>
  )
}