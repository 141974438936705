// This function will remove duplicate features
export function uniqFeatures(features) {
  // generates a key from the item's coordinates.
  function keyFor(item) {
    return item.geometry.coordinates[0] + ':' + item.geometry.coordinates[1];
  }
  
  // index the entries by their coordinates such that
  // indexed['lat:lng'] == the entry.
  const indexed = {};
  features.forEach(function(item) {
    // a duplicate key will replace the existing entry
    indexed[keyFor(item)] = item;
  });
  
  // turn the results back into an array of just values.
  const result = Object.keys(indexed).map(function(k) {
    return indexed[k];
  });
  
  // emit the results.
  return(result);
}