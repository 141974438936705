import shp from 'shpjs'

export default function ShpFileInput({features, setFeatures}) {
  const handleFile = (e) => {
    var reader = new FileReader();
    var file = e.target.files[0];
    reader.readAsArrayBuffer(file);
    reader.onload = function (buffer) {
      const newFeatures = shp.parseShp(buffer.currentTarget.result).map((f) => (
        {
          geometry: f,
          properties: {source: file.name},
          type: "Feature"
        }
      ))
      
      setFeatures([...features, ...newFeatures])
    }
  }

  return (
    <div className='control-group float optional field_gps_acres'>
      <label>
        Upload ShapeFile (.shp):
      </label>
      <input type="file" accept=".shp" onChange={handleFile} />
    </div>
  );
} 
