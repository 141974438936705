import React, { useState, useEffect } from 'react';
import { Marker, useMapEvents, useMap, Rectangle, MapContainer } from 'react-leaflet';
import { TileLayer } from 'react-leaflet/TileLayer';
import Control from 'react-leaflet-custom-control'
import { IoIosPin } from "react-icons/io";
import AgMarker from '../images/ag_mark_small-2x.png'
import L from 'leaflet';

const icon = L.icon({ iconUrl: AgMarker, iconAnchor: [15, 35] });

function MapClickTracker({ setPosition }) {
  const map = useMapEvents({
    click: ({ latlng: { lat, lng } }) => {
      setPosition([lat, lng])
      // map.setView([lat, lng])
    }
  })
  return null
}

function ZoomToOperation({ legalBounds }) {
  const map = useMap();
  return (
    <Control prepend position='bottomright'>
      <button
        disabled={!legalBounds}
        onClick={(e) => {
          e.preventDefault() 
          map.fitBounds(legalBounds)
        }}
      >
        <div style={{display: "flex", flexWrap: "wrap"}}>
          <span style={{margin: '5px'}}>
            Zoom To Operation
          </span>
        </div>
      </button>
    </Control>
  )
}

export default function StoragesFormMap({ gpsData, siteId: defaultSiteId }) {
  const defaultCoords = [41.73583039, -95.70333052];
  const gpsCoords = gpsData?.split(', ');
  const [position, zsetPosition] = useState(gpsCoords)
  const [isEditable, setIsEditable] = useState(false)
  const [legalDesc, setLegalDesc] = useState(null)
  const [siteId, setSiteId] = useState(defaultSiteId)
  const legalBounds = legalDesc?.min_boundry_lat ? [
    [legalDesc?.min_boundry_lat, legalDesc?.min_boundry_lon],
    [legalDesc?.max_boundry_lat, legalDesc?.max_boundry_lon]
  ] : null;
  const [loading, setLoading] = useState(false)
  const setPosition = (v) => {
    if (isEditable) {
      zsetPosition(v)
      setIsEditable(false)
    }
  } 

  useEffect(
    () => {
      if (position) {
        document.getElementById('storage_gps').value = `${position[0]}, ${position[1]}`
        document.getElementById('storage_geo_json').value = `{\"type\":\"FeatureCollection\",\"features\":[{\"type\":\"Feature\",\"properties\":{},\"geometry\":{\"type\":\"Point\",\"coordinates\":[${position[1]},${position[0]}]}}]}`
      };
    },
    [JSON.stringify(position)]
  )

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`/api/sites/${siteId}/legal_address`);
      const data = await response.json();
      return (data)
    }

    if (siteId) {
      setLoading(true)
      // call the function
      fetchData()
        .then((res) => {
          setLegalDesc(res.legal_address)
          setLoading(false)
        })
        // make sure to catch any error
        .catch(console.error);
    }
  }, [siteId])

  useEffect(() => {
    $('#storage_site_id').chosen().change((event) => setSiteId(event.target.value)) 
  }, [])

  if (loading) return <p>Loading...</p>
  return (
    <>
      <div className="control-group string optional storage_gps">

        <label className="string optional control-label" for="gps-react">GPS</label>
        <div className='controls'>
          <input
            className="string optional span12" 
            id="gps-react"
            value={position ? `${position[0]}, ${position[1]}` : ''}
            onChange={({ target: { value }}) => zsetPosition(value.split(", ")) }
          />
        </div>
      </div>
      <MapContainer 
        center={gpsCoords || defaultCoords} 
        zoom={gpsCoords ? 18 : 10}
        scrollWheelZoom
        style={{ height: '400px' }}
        >
        <TileLayer
          url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
          maxZoom= {20}
          subdomains={['mt1','mt2','mt3']}
        />
          <Control prepend position='bottomleft'>
            <button onClick={(e) => {
              e.preventDefault() 
              setIsEditable(!isEditable)
            }
          }>
            <div style={{display: "flex", flexWrap: "wrap"}}>
              {isEditable ? <p style={{margin: '4px'}}>Cancel</p> : null }
              <span style={{margin: '5px'}}>
                <IoIosPin />
              </span>
            </div>
          </button>
          </Control>
          <ZoomToOperation legalBounds={legalBounds} />
        { position ?  (<Marker position={position} icon={icon} />): null}
        <MapClickTracker setPosition={setPosition} />
        { legalDesc ? <Rectangle bounds={legalBounds} pathOptions={{ color: 'red', fillOpacity: 0 }} /> : null }
      </MapContainer>
    </>
  );
}
