import React from 'react';
import { GeoJSON, MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import bbox from 'geojson-bbox';
import ActivitySheetLayers from './ActivitySheetLayers';

export default function ActivitiesReadOnlyMap({ fieldOutline, prescribedActivityOutline, activitySheets }) {
  const extent = bbox(fieldOutline); 
  return(
    <MapContainer
      bounds={[[extent[1],extent[0]], [extent[3], extent[2]]]}
      scrollWheelZoom
      style={{ height: '420px' }}
    >
      <TileLayer
        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
        maxZoom= {20}
        subdomains={['mt1','mt2','mt3']}
      />
      <LayersControl position="topright">
        <LayersControl.Overlay checked name="Field Outline">
          <GeoJSON data={fieldOutline} pathOptions={{ color: '#6988E7', fillOpacity: 0 }} />
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Prescribed Activity">
          <GeoJSON data={prescribedActivityOutline} pathOptions={{ color: 'yellow' }} />
        </LayersControl.Overlay>
        <ActivitySheetLayers activitySheets={activitySheets} />
      </LayersControl>
    </MapContainer>
  )
}
