import React from 'react';
import { GeoJSON, LayersControl } from 'react-leaflet';

export default function ActivitySheetLayers ({ activitySheets }) {

  return (
    <>
      {
        activitySheets?.map(as => as.applications.map(actualActivity => {
          let features;
          try {
            features = JSON.parse(actualActivity.geo_json);
          } catch {
            // do nothing
          }

          if (features?.length) return (
          <LayersControl.Overlay checked name={`Act Sheet ${as.id} - Actual actualActivity ${actualActivity.id}`}>
            <GeoJSON data={{features}} pathOptions={{ color: 'orange', weight: 1 }}/>
          </LayersControl.Overlay>
        )}
        ))
      }
    </>
  )
}