import { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import useLegalAddress from '../../hooks/useLegalAddress';
const mapping = {
  legal1: "legal-q1",
  legal2: "legal-q2",
  section: "legal-section", 
  township: "legal-township",
  township_direction: "legal-township-dir",
  range: "legal-range",
  range_direction: "legal-range-dir",
  state: "legal-state",
}

export default function LegalInput({ legalAddressRecord, setLegalAddressRecord, legalInputVal, setLegalInputVal }) {
  const [loading, setLoading] = useState(false);

  const debouncedValue = useDebounce(legalInputVal, 500);
  useLegalAddress(debouncedValue, legalInputVal, setLoading, setLegalAddressRecord)

  function onChangeInput({ target: { id, value } }) {
    document.getElementById(mapping[id]).value = value;
    setLegalInputVal({...legalInputVal, [id]: value })
  }

  useEffect(() => {
    if (legalAddressRecord?.township_name) {
      document.getElementById('field_township').value = legalAddressRecord.township_name;
    }
    if (legalAddressRecord?.county_name) {
      document.getElementById('field_county').value = legalAddressRecord.county_name;
    }
}, [legalAddressRecord?.id])

  return (
    <div className="control-group float optional field_gps_acres">
      <label className="float optional control-label" htmlFor="field_gps_acres_js">
        Legal Description
      </label>
      <div className="controls">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <input style={{ width: "2em", marginRight: '2px', marginLeft: '2px' }} id="legal1" type="text" onChange={onChangeInput} value={legalInputVal.legal1} />
          <p style={{ color: '#666', margin: '3px' }}>1/4 of the</p>
          <input style={{ width: "2em", marginRight: '2px', marginLeft: '2px'  }} id="legal2" type="text" onChange={onChangeInput} value={legalInputVal.legal2} />
          <p style={{ color: '#666', margin: '3px' }}>1/4 of Sec</p>
          <input style={{ width: "3em", marginRight: '2px', marginLeft: '2px'  }} id="section" type="text" onChange={onChangeInput} value={legalInputVal.section} />
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: '5px'}}>
          <p style={{ color: '#666', margin: '3px' }}>T</p>
          <input style={{ width: "3em", marginRight: '2px', marginLeft: '2px'  }} id="township" type="text" onChange={onChangeInput} value={legalInputVal.township} />
          <input style={{ width: "1em", marginRight: '2px', marginLeft: '2px'  }} id="township_direction" type="text" onChange={onChangeInput} value={legalInputVal.township_direction} />
          <p style={{ color: '#666', margin: '3px' }}>R</p>
          <input style={{ width: "3em", marginRight: '2px', marginLeft: '2px'  }} id="range" type="text" onChange={onChangeInput} value={legalInputVal.range} />
          <input style={{ width: "1em", marginRight: '2px', marginLeft: '2px'  }} id="range_direction" type="text" onChange={onChangeInput} value={legalInputVal.range_direction} />
          <p style={{ color: '#666', margin: '3px' }}>State</p>
          <input style={{ width: "2em", marginRight: '2px', marginLeft: '2px'  }} id="state" type="text" onChange={onChangeInput} value={legalInputVal.state} />
        </div>
      </div>
    </div>
  );
}