import React, { useRef, useState } from 'react';
import { GeoJSON, MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import bbox from 'geojson-bbox';
import AgMarker from '../images/ag_mark_small-2x.png'
import { EditControl } from "react-leaflet-draw";
import L from 'leaflet';

function extractLayers(ref) {
  let layers = [];

  ref.current.eachLayer((layer) => {
    layers.push(layer.toGeoJSON());
  })

  return layers;
}

const icon = L.icon({ iconUrl: AgMarker, iconAnchor: [15, 35] });

export default function FieldMapWithPinEdit({ fieldGeoJson, id, toClass }) {
  const fieldRef = useRef();
  const points = fieldGeoJson.features.filter(f => f.geometry?.type === 'Point')
  const polygons = fieldGeoJson.features.filter(f => f.geometry?.type === 'Polygon')

  // Stockpiles only exist for Fields, not storages
  const stockpiles = { type: 'FeatureCollection', features: toClass === 'Field' ? points : []}
  const outline = { type: 'FeatureCollection', features: toClass === 'Field' ? polygons : fieldGeoJson.features }

  const [features, setFeatures] = useState(stockpiles?.features || [])
 
  const containerProps = {};
  try {
    const extent = bbox(fieldGeoJson);
    containerProps['bounds'] = [[extent[1],extent[0]], [extent[3], extent[2]]]
  } catch (exceptionVar) {
    containerProps['center'] = [41.73583039, -95.70333052];
    containerProps['zoom'] = 10;
  }

  function createMarker(_feature, latlng) {
    return L.marker(latlng, { icon });
  }

  // this will not trigger on the initial layer load, only for user based inputs.
  const onUpdate = () => {
    setFeatures(extractLayers(fieldRef))
  };

  // add existing layers to the map
  const onMounted = () => {
    // this layer will never exist on the map, it is only used to parse the geojson layers.
    let leafletGeoJSON = new L.GeoJSON({ type: 'FeatureCollection', features }, { pointToLayer: createMarker });
    let leafletFG = fieldRef.current;

    leafletGeoJSON.eachLayer(layer => {

      leafletFG.addLayer(layer)
    });
  };

  const csrfToken = document.querySelector("[name='csrf-token']").content

  const options = {
    method: 'PATCH',
    headers: { "X-CSRF-Token": csrfToken, "content-type": 'application/json' },
    body: JSON.stringify({
      stockpiles_attributes: features.map(f => ({
        id: f.properties.options?.id,
        longitude: f.geometry.coordinates[0],
        latitude: f.geometry.coordinates[1],
      }))
    })
  };

  const checkStatus = async (response) => {
    if (response.status >= 200 && response.status < 300) 
      return await response.json()
    throw await response.json()
  }

  function updateData() {
    fetch(`/api/distribution_sales/${id}/update_to_obj_geojson`, options)
    .then(checkStatus)
    // .then(r => {
    //   console.log(r)
    //   // setFeatures(geoJson.features)
    // })
    .catch((e) => 'setErrors(e.errors)')
  }

  React.useEffect(() => {
    if(JSON.stringify(stockpiles?.features) !== features) {
      updateData()
    }
  }, [JSON.stringify(features)])

  return (
    <MapContainer
      scrollWheelZoom
      style={{ height: '420px' }}
      {...containerProps}
    >
      <TileLayer
        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
        maxZoom= {20}
        subdomains={['mt1','mt2','mt3']}
      />
      <GeoJSON data={outline} pathOptions={{ color: '#6988E7' }} pointToLayer={createMarker} />
      {
        toClass === 'Field'
          ? (
            <FeatureGroup
              key={JSON.stringify(stockpiles.features)}
              pathOptions={{ color: '#6988E7' }}
              ref={fieldRef}
              pointToLayer={createMarker}
            >
              <EditControl
                position='bottomleft'
                draw={{
                  circlemarker: false,
                  polygon: false,
                  marker: { icon },
                  circle: false,
                  polyline: false,
                  rectangle: false
                }}
                onCreated={onUpdate}
                onDeleted={onUpdate}
                onEdited={onUpdate}
                onMounted={onMounted}
              />
            </FeatureGroup>
          )
        :  null
      }
    </MapContainer>
  );
}
